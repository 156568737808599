<template>
  <div class="product-edit main-panel large" v-loading="isGetDetail">
    <h3 class="panel-title" v-if="!layerInfo?.id">产品资料</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.id}"
               :loading="isLoading"
               @click="save">保存</el-button>
    <div class="main-wrap">
      <div class="left">
        <el-form :model="form"
                 :rules="formRules"
                 ref="productForm"
                 label-width="65px"
                 @submit.prevent>
          <h3 class="title">基础信息</h3>
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="品名" prop="pname">
                <el-input v-model.lazy.trim="form.pname" :size="controlSize" placeholder="品名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="胚布名" prop="clothName">
                <el-input v-model.trim="form.clothName" :size="controlSize" placeholder="胚布名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="幅宽">
                <el-input v-model.trim="form.width" :size="controlSize" placeholder="幅宽"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="克重">
                <el-input v-model.trim="form.weight" :size="controlSize" placeholder="克重"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="成分">
                <el-input v-model.trim="form.element" :size="controlSize" placeholder="成分"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <h3 class="title">其他信息</h3>
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="单位" prop="unit">
                <el-select :size="controlSize" class="cg-input" filterable v-model="form.unit" placeholder="请选择单位" value-key="id">
                  <el-option v-for="item in units" :key="item.id" :label="item.name" :value="item" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纱支">
                <el-input v-model.trim="form.shazhi" :size="controlSize" placeholder="纱支"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="密度">
                <el-input v-model.trim="form.density" :size="controlSize" placeholder="密度"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input v-model.trim="form.content" :size="controlSize" placeholder="备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="right">
        <color-list :list="colorList" @add-row="addRow" @input-color="inputColor" @del="delColor" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorList from "./ColorList/ColorList";

export default {
  components: {
    ColorList
  },

  props: {
    layerInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    const validateUnit = (rule, value, callback) => {
      if (!value.id) {
        callback(new Error('请选择单位'));
      } else {
        callback();
      }
    }

    return {
      controlSize: "mini",
      form: {
        pname: "", // 品名
        clothName: "", // 胚布名
        width: "", // 幅宽
        weight: "", // 克重
        element: "", // 成分
        unit: {}, // 单位
        shazhi: "", // 纱支
        density: "", // 密度
        content: "" // 备注
      },
      formRules: {
        pname: [{required: true, message: "请填写品名", trigger: "blur"}],
        clothName: [{required: true, message: "请填写胚布名", trigger: "blur"}],
        unit: [{required: true, validator: validateUnit, trigger: "change"}]
      },

      colorList: [], // 颜色列表
      delColorList: [], // 待删除颜色列表

      units: [],
      isGetDetail: false,
      isLoading: false
    }
  },

  watch: {
    "form.pname": function (val) {
      this.form.pname = val.replace(/\s/g, "")
    }
  },

  async mounted() {
    await this.init();
    this.layerInfo?.id && await this.getDetail();
    !this.colorList.length && this.addRow(10);
  },

  methods: {
    async init() {
      this.$showLoading()
      this.units = await this.$getList.getUnits()
      this.$hideLoading()
    },

    async getDetail() {
      this.isGetDetail = true;
      const res = await this.$api.Base.Product.getDetail(this.layerInfo.id).catch(() => {})
      this.isGetDetail = false;

      if (res?.code === 200) {
        const product = res.data.product;
        this.form = {
          pname: product.pname || "", // 品名
          clothName: product.fabricName || "", // 胚布名
          width: product.width || "", // 幅宽
          weight: product.weight || "", // 克重
          element: product.ingredients || "", // 成分
          unit: this.units.find(i => i.name === product.companyname) || {}, // 单位
          shazhi: product.gauzebranch || "", // 纱支
          density: product.density || "", // 密度
          content: product.content || "" // 备注
        }
        this.colorList = res.data.colorcards.map(i => ({id: i.id, colorNum: i.colornumber || "", colorName: i.colorname || "", delflag: 1}));
      }
    },

    addRow(num) {
      const lastIndex = this.colorList.length
      this.colorList.push(...new Array(num).fill({}).map((i, k) => ({id: null, colorNum: `${lastIndex + k + 1}#`, colorName: ""})))
    },

    inputColor(value, type, index) {
      this.colorList[index][type] = value
    },

    delColor(index) {
      if (this.colorList[index].id) {
        const delItem = this.colorList[index];
        this.delColorList.push({...delItem, delflag: 0});
      }
      this.colorList.splice(index, 1);
    },

    save() {
      this.$refs["productForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true;
          const sendData = {
            product: {
              pname: this.form.pname,
              fabricName: this.form.clothName,
              width: this.form.width,
              weight: this.form.weight,
              ingredients: this.form.element,
              companyname: this.form.unit.name,
              gauzebranch: this.form.shazhi,
              density: this.form.density,
              manufactorId: this.form.number,
              content: this.form.content
            }
          };

          this.colorList.length && (sendData.colorcards = this.colorList
              .filter(i => i.id || (!i.id && (i.colorNum.trim() || i.colorName.trim())))
              .map(i => ({id: i.id || null, colornumber: i.colorNum, colorname: i.colorName, delflag: Number(i.colorNum.trim() || i.colorName.trim())})).concat(this.delColorList))

          if (this.layerInfo?.id) {
            sendData.product.id = this.layerInfo.id;
            const res = await this.$api.Base.Product.update(sendData).catch(() => {});
            this.isLoading = false;

            if (res?.code === 200) {
              this.$message.success("修改成功");
              this.$emit("handle");
            } else {
              this.$message.error(res?.msg || "修改失败")
            }
          } else {
            const res = await this.$api.Base.Product.add(sendData).catch(() => {});
            this.isLoading = false;

            if (res?.code === 200) {
              this.$message.success("保存成功");
              Object.assign(this.$data, this.$options.data());
              await this.$nextTick()
              this.$refs["productForm"].resetFields();
              await this.init();
              this.addRow(10);
            } else {
              this.$message.error(res?.msg || "保存失败")
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "product-edit";
</style>
