<template>
  <div class="color-list">
    <h3 class="title">颜色</h3>
    <div class="top">
      <div class="add-btn-group">
        <div class="add-btn" @click="addRow(10)">+10</div>
        <div class="add-btn" @click="addRow(30)">+30</div>
        <div class="add-btn" @click="addRow(50)">+50</div>
        <div class="add-btn" @click="addRow(100)">+100</div>
      </div>
    </div>

    <div class="table">
      <div class="row ">
        <div class="cell color-num th">色号</div>
        <div class="cell color-name th">颜色名称</div>
      </div>
      <div class="row" v-for="(item, index) in list" :key="index">
        <i class="el-icon-delete del" title="删除" @click.stop="del(index)"></i>
        <div class="cell color-num"><input type="text" :value="item.colorNum" @input="inputColor($event.target.value, 'colorNum', index)" style="padding-left: 26px;"></div>
        <div class="cell color-name"><input type="text" :value="item.colorName" @input="inputColor($event.target.value, 'colorName', index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    addRow(num) {
      this.$emit("addRow", num)
    },

    inputColor(value, type, index) {
      this.$emit("inputColor", value, type, index)
    },

    del(index) {
      this.$emit("del", index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "ColorList";
</style>
